/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useMemo, useRef } from "react";
import * as THREE from "three";
import { useGLTF, MeshDistortMaterial } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useXR } from "@react-three/xr";
import lerp from "lerp";

export default function Model({ mouse, ...props }) {
  const group = useRef();
  const { nodes } = useGLTF(
    "https://shard.rayce.live/PORTFOLIO-transformed.glb"
  );
  const dist = 0.25;
  const rough = 0.0;
  const speed = 0.7;
  const { size, viewport } = useThree();
  const { isPresenting } = useXR();
  const aspect = size.width / viewport.width;

  useFrame((state) => {
    const rotX = !isPresenting
      ? -(Math.PI / 2) - (mouse.current[1] / aspect) * 0.008
      : -(Math.PI / 2);

    const rotZ = !isPresenting ? 0 - (mouse.current[0] / aspect) * 0.01 : 0;
    group.current.rotation.x = lerp(group.current.rotation.x, rotX, 0.1);
    group.current.rotation.z = lerp(group.current.rotation.z, rotZ, 0.1);
  });

  useEffect(() => {
    group.current.scale.x = isPresenting ? 0.5 : 1;
    group.current.scale.y = isPresenting ? 0.5 : 1;
    group.current.scale.z = isPresenting ? 0.5 : 1;
    group.current.position.y = isPresenting ? -0.1 : -2.4;
    group.current.position.z = isPresenting ? -2.1 : 0.35;
  }, [isPresenting]);

  return (
    <group
      ref={group}
      {...props}
      //scale={4 / aspect}
      dispose={null}
      onClick={(e) => window.open("mailto:stip.rayce@gmail.com", "_blank")}
    >
      <group>
        <mesh
          wireframe={true}
          geometry={nodes.TRest.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          wireframe={true}
          geometry={nodes.Text.geometry}
          position={[0.13, 3.97, -3.28]}
          scale={0.61}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        >
        <mesh
          wireframe={true}
          geometry={nodes.Text006.geometry}
          position={[0, 0.23, -4.22]}
          rotation={[0, -0.04, 0]}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          wireframe={true}
          geometry={nodes.Text012.geometry}
          position={[-0.16, 2, -4.01]}
          rotation={[0, -0.04, 0]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text001.geometry}
          position={[0.17, 3.87, -3.83]}
          rotation={[0, -0.04, 0]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text016.geometry}
          position={[-0.06, 1.4, -4.1]}
          rotation={[0, -0.04, 0]}
          scale={0.92}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text017.geometry}
          position={[-0.15, 2.66, -3.92]}
          rotation={[0, -0.04, 0]}
          scale={0.86}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text018.geometry}
          position={[-0.16, 4.28, -3.79]}
          rotation={[0, -0.04, 0]}
          scale={0.73}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest001.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest002.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest003.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest004.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest005.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest006.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest007.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest008.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest009.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest010.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest011.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest012.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest013.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest014.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest015.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest016.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest017.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest018.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest019.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest020.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest021.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest022.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest023.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest024.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest025.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest026.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest027.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest028.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest029.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest030.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest031.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest032.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest033.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest034.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest035.geometry}
          position={[1.09, -21.79, 6.6]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={2.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest036.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest037.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest038.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest039.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest040.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest041.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest042.geometry}
          position={[0.03, 2.89, -0.55]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={0.72}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest043.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest044.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest045.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest046.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest047.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest048.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest049.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.TRest050.geometry}
          position={[0.5, -10.82, 3.43]}
          rotation={[-0.01, -0.12, -0.05]}
          scale={1.98}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text028.geometry}
          position={[0.13, 3.97, -3.28]}
          scale={0.61}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text029.geometry}
          position={[0.13, 3.97, -3.28]}
          scale={0.61}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text030.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text031.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text032.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text033.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text034.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text035.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text036.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text037.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text038.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text039.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text040.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text041.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text042.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text043.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text044.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text045.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text046.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text047.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text048.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text049.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text050.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text051.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text052.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text053.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text054.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text055.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text056.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text057.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text058.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text059.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text060.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text061.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text062.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text063.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text064.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text065.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text066.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text067.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text068.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text069.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text070.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text071.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text072.geometry}
          position={[0.36, -12.14, -3.38]}
          scale={1.93}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text073.geometry}
          position={[0.36, -12.14, -3.38]}
          scale={1.93}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text074.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text075.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text076.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text077.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text078.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text079.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text080.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text081.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text082.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text083.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text084.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text085.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text086.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text087.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text088.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text089.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text090.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text091.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text092.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text093.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text094.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text095.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text096.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text097.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text098.geometry}
          position={[-0.08, 3.58, -3.39]}
          scale={0.75}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text099.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text100.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text101.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text102.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text103.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text104.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text105.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text106.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text107.geometry}
          position={[-0.14, 3.66, -3.36]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text108.geometry}
          position={[-0.14, 3.66, -3.36]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text109.geometry}
          position={[-0.14, 3.66, -3.36]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text110.geometry}
          position={[-0.11, -4.12, -3.42]}
          scale={1.3}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text111.geometry}
          position={[-0.11, -4.12, -3.42]}
          scale={1.3}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text112.geometry}
          position={[-0.11, -4.12, -3.42]}
          scale={1.3}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text113.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text114.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text115.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text116.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text117.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text118.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text119.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text120.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text121.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text122.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text123.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text124.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text125.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text126.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text127.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text128.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text131.geometry} position={[-0.01, 0.95, -3.34]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text132.geometry}
          position={[0.02, 1.63, -3.37]}
          scale={0.94}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text133.geometry}
          position={[0.02, 1.63, -3.37]}
          scale={0.94}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text134.geometry}
          position={[0.02, 1.63, -3.37]}
          scale={0.94}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text135.geometry}
          position={[0.02, 1.63, -3.37]}
          scale={0.94}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text142.geometry}
          position={[0.04, 3.07, -3.26]}
          scale={0.85}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text144.geometry} position={[0.33, 1.4, -3.36]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text145.geometry} position={[0.33, 1.4, -3.36]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text147.geometry}
          position={[0.13, 3.97, -3.28]}
          scale={0.61}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text148.geometry}
          position={[0.13, 3.97, -3.28]}
          scale={0.61}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text149.geometry}
          position={[0.13, 3.97, -3.28]}
          scale={0.61}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text150.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text151.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text152.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text153.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text154.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text155.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text156.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text157.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text158.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text159.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text160.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text161.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text162.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text163.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text164.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text165.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text166.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text167.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text168.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text169.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text170.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text171.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text172.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text173.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text174.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text175.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text176.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text177.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text178.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text179.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text180.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text181.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text182.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text183.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text184.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text185.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text186.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text187.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text188.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text189.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text190.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text191.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text192.geometry}
          position={[0.36, -12.14, -3.38]}
          scale={1.93}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text193.geometry}
          position={[0.36, -12.14, -3.38]}
          scale={1.93}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text194.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text195.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text196.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text197.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text198.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text199.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text200.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text201.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text202.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text203.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text204.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text205.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text206.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text207.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text208.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text209.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text210.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text211.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text212.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text213.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text214.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text215.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text216.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text217.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text218.geometry}
          position={[-0.08, 3.58, -3.39]}
          scale={0.75}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text219.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text220.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text221.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text222.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text223.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text224.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text225.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        />
        <mesh
          geometry={nodes.Text226.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text227.geometry}
          position={[-0.14, 3.66, -3.36]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text228.geometry}
          position={[-0.14, 3.66, -3.36]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text229.geometry}
          position={[-0.14, 3.66, -3.36]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text230.geometry}
          position={[-0.11, -4.12, -3.42]}
          scale={1.3}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text231.geometry}
          position={[-0.11, -4.12, -3.42]}
          scale={1.3}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text232.geometry}
          position={[-0.11, -4.12, -3.42]}
          scale={1.3}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text233.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text234.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text235.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text236.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text237.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text238.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text239.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text240.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text241.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text242.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text243.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text244.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text245.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text246.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text247.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text248.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text252.geometry}
          position={[0.02, 1.63, -3.37]}
          scale={0.94}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text253.geometry}
          position={[0.02, 1.63, -3.37]}
          scale={0.94}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text254.geometry}
          position={[0.02, 1.63, -3.37]}
          scale={0.94}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text256.geometry}
          position={[0.04, 3.07, -3.26]}
          scale={0.85}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text257.geometry}
          position={[0.04, 3.07, -3.26]}
          scale={0.85}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text258.geometry}
          position={[0.04, 3.07, -3.26]}
          scale={0.85}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text266.geometry} position={[0.33, 1.4, -3.36]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text270.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text273.geometry}
          position={[0.04, 3.07, -3.26]}
          scale={0.85}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text275.geometry}
          position={[0.04, 3.07, -3.26]}
          scale={0.85}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text282.geometry}
          position={[0.02, 1.63, -3.37]}
          scale={0.94}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text283.geometry}
          position={[0.02, 1.63, -3.37]}
          scale={0.94}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text284.geometry}
          position={[0.02, 1.63, -3.37]}
          scale={0.94}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text287.geometry} position={[-0.01, 0.95, -3.34]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text289.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text290.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text291.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text292.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text293.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text294.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text295.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text296.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text297.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text298.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text299.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text300.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text301.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text302.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text303.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text304.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text305.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text306.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text307.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text308.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text309.geometry}
          position={[-0.09, 3.04, -3.37]}
          scale={0.74}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text310.geometry}
          position={[-0.11, -4.12, -3.42]}
          scale={1.3}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text311.geometry}
          position={[-0.11, -4.12, -3.42]}
          scale={1.3}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text312.geometry}
          position={[-0.11, -4.12, -3.42]}
          scale={1.3}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text313.geometry}
          position={[-0.11, -4.12, -3.42]}
          scale={1.3}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text314.geometry}
          position={[-0.11, -4.12, -3.42]}
          scale={1.3}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text315.geometry}
          position={[-0.14, 3.66, -3.36]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text316.geometry}
          position={[-0.14, 3.66, -3.36]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text317.geometry}
          position={[-0.14, 3.66, -3.36]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text318.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text319.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text320.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text321.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text322.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text323.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text324.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text325.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text326.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text327.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text328.geometry}
          position={[-0.01, 1.16, -3.39]}
          scale={0.91}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text329.geometry}
          position={[-0.08, 3.58, -3.39]}
          scale={0.75}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text330.geometry}
          position={[-0.08, 3.58, -3.39]}
          scale={0.75}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text331.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text332.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text333.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text334.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text335.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text336.geometry}
          position={[0.1, -2.95, -3.43]}
          scale={1.26}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text337.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text338.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text339.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text340.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text341.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text342.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text343.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text344.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text345.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text346.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text347.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text348.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text349.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text350.geometry}
          position={[-0.01, 1.72, -3.39]}
          scale={0.84}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text351.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text352.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text353.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text354.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text355.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text356.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text357.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text358.geometry}
          position={[-0.12, 2.45, -3.36]}
          scale={0.79}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text359.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text360.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text361.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text362.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text363.geometry}
          position={[0.02, -1.55, -3.32]}
          scale={1.06}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text364.geometry}
          position={[0.36, -12.14, -3.38]}
          scale={1.93}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text365.geometry}
          position={[0.36, -12.14, -3.38]}
          scale={1.93}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text366.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text367.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text368.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text369.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text370.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text371.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text372.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text373.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text374.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text375.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text376.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text377.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text378.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text379.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text380.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text381.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text382.geometry}
          position={[0, 2.93, -3.28]}
          scale={0.69}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text383.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text384.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text385.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text386.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text387.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text388.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text389.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text390.geometry}
          position={[-0.08, 3.78, -3.38]}
          scale={0.7}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text391.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text392.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text393.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text394.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text395.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text396.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text397.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text398.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text399.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text400.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text401.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text402.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text403.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text404.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text405.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text406.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text407.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text408.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text409.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text410.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text411.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text412.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text413.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text414.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text415.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text416.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text417.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text418.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text419.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text420.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh geometry={nodes.Text421.geometry} position={[-0.01, 0.02, -3.39]}>
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text422.geometry}
          position={[0.13, 3.97, -3.28]}
          scale={0.61}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text423.geometry}
          position={[0.13, 3.97, -3.28]}
          scale={0.61}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Text424.geometry}
          positn={[0.13, 3.97, -3.28]}
          scale={0.61}
        >
          <MeshDistortMaterial
            color="#ffffff"
            attach="material"
            distort={dist} // Strength, 0 disables the effect (default=1)
            speed={speed} // Speed (default=1)
            roughness={rough}
            wireframe={true}
          />
        </mesh>
      </group>
    </group>
  );
}

useGLTF.preload("/PORTFOLIO-transformed.glb");
