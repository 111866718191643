import "./styles.css";
import * as THREE from "three";
import {
  useRef,
  useCallback,
  useMemo,
  useState,
  Suspense,
  useEffect
} from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { VRCanvas, useXR, DefaultXRControllers } from "@react-three/xr";
import Model from "./Model.js";

import { PerspectiveCamera } from "@react-three/drei";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

function Swarm({ count, mouse }) {
  const mesh = useRef();
  const light = useRef();
  const { size, viewport } = useThree();
  const aspect = size.width / viewport.width;

  const dummy = useMemo(() => new THREE.Object3D(), []);
  // Generate some  random positions, speed factors and timings
  const particles = useMemo(() => {
    const temp = [];
    for (let i = 0; i < count; i++) {
      const t = Math.random() * 100;
      const factor = 20 + Math.random() * 100;
      const speed = 0.001 + Math.random() / 500;
      const xFactor = -50 + Math.random() * 100;
      const yFactor = -50 + Math.random() * 100;
      const zFactor = -50 + Math.random() * 100;
      temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 });
    }
    return temp;
  }, [count]);
  // The innards of this hook will run every frame
  useFrame((state) => {
    // Makes the light follow the mouse
    light.current.position.set(
      (-mouse.current[0] / aspect) * 0.2,
      (mouse.current[1] / aspect) * 0.2,
      -5
    );
    // Run through the randomized data to calculate some movement
    particles.forEach((particle, i) => {
      let { t, factor, speed, xFactor, yFactor, zFactor } = particle;
      // There is no sense or reason to any of this, just messing around with trigonometric functions
      t = particle.t += speed / 2;
      const a = Math.cos(t) + Math.sin(t * 1) / 10;
      const b = Math.sin(t) + Math.cos(t * 2) / 10;
      const s = Math.cos(t);
      particle.mx += (mouse.current[0] - particle.mx) * 0.01;
      particle.my += (mouse.current[1] * -1 - particle.my) * 0.01;
      // Update the dummy object
      dummy.position.set(
        (particle.mx / 10) * a +
          xFactor +
          Math.cos((t / 10) * factor) +
          (Math.sin(t * 1) * factor) / 10,
        (particle.my / 10) * b +
          yFactor +
          Math.sin((t / 10) * factor) +
          (Math.cos(t * 2) * factor) / 10,
        (particle.my / 10) * b +
          zFactor +
          Math.cos((t / 10) * factor) +
          (Math.sin(t * 3) * factor) / 10
      );
      dummy.scale.set(s, s, s);
      dummy.rotation.set(s * 5, s * 5, s * 5);
      dummy.updateMatrix();
      // And apply the matrix to the instanced item
      mesh.current.setMatrixAt(i, dummy.matrix);
    });
    mesh.current.instanceMatrix.needsUpdate = true;
  });
  return (
    <>
      <pointLight ref={light} distance={5} intensity={4} color="lightblue" />
      <instancedMesh ref={mesh} args={[null, null, count]}>
        <dodecahedronBufferGeometry attach="geometry" args={[0.08, 0]} />
        <meshPhongMaterial attach="material" color="#BBBBBB" />
      </instancedMesh>
    </>
  );
}

const World = ({ isMobile, mouse }) => {
  const spinnyLight1 = useRef();
  const XRcamera = useRef();
  const model = useRef();
  const intensity = 0.5;

  const { player, isPresenting } = useXR();

  useEffect(() => {
    if (isPresenting) {
      player.position.x = 0;
      player.position.y = 0;
      player.position.z = -5;
      player.rotation.x = 0;
      player.rotation.y = Math.PI;
      player.rotation.z = 0;
      XRcamera.current.rotation.x = 0;
      XRcamera.current.rotation.y = Math.PI;
      XRcamera.current.rotation.z = 0;
    } else {
      player.position.x = 0;
      player.position.y = 0;
      player.position.z = 0;
      player.rotation.x = 0;
      player.rotation.y = 0;
      player.rotation.z = 0;
      XRcamera.current.makeDefault = true;
      XRcamera.current.position.x = 0.35;
      XRcamera.current.position.y = 0.7;
      XRcamera.current.position.z = -11.5;
      XRcamera.current.fov = isMobile ? 72 : 36;
      XRcamera.current.rotation.x = 0;
      XRcamera.current.rotation.y = Math.PI;
      XRcamera.current.rotation.z = 0;
    }
  }, [player, isPresenting, isMobile]);

  useFrame((state) => {
    spinnyLight1.current.rotation.y += 0.003;
  });

  return (
    <>
      <fog attach="fog" args={["black", 20, 50]} />
      <Swarm count={isMobile ? 2000 : 10000} mouse={mouse} />
      <Suspense fallback={null}>
        <Model
          mouse={mouse}
          ref={model}
          position={[0, -2.4, 0]}
          rotation={[-Math.PI / 2, Math.PI, 0]}
        />
      </Suspense>
      <group ref={spinnyLight1}>
        <pointLight
          intensity={intensity}
          position={[20, 0, -40]}
          color="#97f2f3"
        />
        <pointLight
          intensity={intensity}
          position={[-20, 0, 40]}
          color="#f1e0b00"
        />
        <pointLight
          intensity={intensity}
          position={[-40, 0, -20]}
          color="#f1cdb0"
        />
        <pointLight
          intensity={intensity}
          position={[40, 0, 20]}
          color="#e7cfc8"
        />
      </group>
      <PerspectiveCamera
        ref={XRcamera}
        position={[0.35, 0.7, -11.5]}
        rotation={[0, Math.PI, 0]}
        makeDefault={true}
        far={100}
        near={0.1}
        fov={isMobile ? 72 : 36}
      />
    </>
  );
};

export default function App() {
  const [down, set] = useState(false);
  const mouse = useRef([0, 0]);
  const onMouseMove = useCallback(
    ({ clientX: x, clientY: y }) =>
      (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]),
    []
  );

  return (
    <VRCanvas
      colorManagement={true}
      dpr={[1, 2]}
      gl={{
        //toneMapping: NoToneMapping,
        antialias: true,
        alpha: false,
        powerPreference: "high-performance",
        colorManagement: true
      }}
      pixelRatio={Math.min(2, isMobile ? window.devicePixelRatio : 1)}
      onMouseMove={onMouseMove}
      onMouseUp={() => set(false)}
      onMouseDown={() => set(true)}
      onCreated={({ gl }) => {
        gl.powerPreference = "high-performance";
        gl.colorManagement = true;
        gl.alpha = false;
        gl.toneMapping = THREE.ACESFilmicToneMapping;
        gl.toneMappingExposure = 0.7;
        gl.setClearColor(new THREE.Color("#000000"));
      }}
    >
      <World mouse={mouse} isMobile={isMobile} />
      <DefaultXRControllers />
    </VRCanvas>
  );
}
